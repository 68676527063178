import React, { Fragment } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import './Form.css'

export default class Form extends React.Component {
  state = {
    NAME: null,
    MESSAGE: null,
    NEWSLETTER: null,
    email: null
  }

  _cb_handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.checked ? "yes" : "no"
    })
    this.setState({
      [`${e.target.name}`]: e.target.checked ? "yes" : "no"
    })
  }

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value
    })
    this.setState({
      [`${e.target.name}`]: e.target.value
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    console.log('submit', this.state)

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)

        if (result !== 'success') {
          throw msg
        }
        alert(
          'Thank you for your inquiry. We will respond at our next oppportunity. Please consider starting a chat with us below!'
        )
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
  }

  render() {
    return (
      <Fragment>
        <form className="Form" onSubmit={this._handleSubmit}>
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              onChange={this._handleChange}
              placeholder="Your Name"
              name="NAME"
              required
            />
            <span>Name</span>
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="email@example.com"
              onChange={this._handleChange}
              name="email"
              required
            />
            <span>Email address</span>
          </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Message"
              onChange={this._handleChange}
              name="MESSAGE"
              rows="10"
              required
            />
            <span>Message</span>
          </label>
          <label className="Form--Label Form-Checkbox">
            <input
              className="Form--Input Form--Textarea Form--CheckboxInput"
              onChange={this._cb_handleChange}
              name="NEWSLETTER"
              type="checkbox"
            />
            <span>Get news updates</span>
          </label>
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Enquire"
            disabled={this.state.disabled}
          />
        </form>
      </Fragment>
    )
  }
}
